import {
    Box, Button, Card, CardContent, Container, Grid, MenuItem, Select, TextField, Typography
} from "@mui/material";
import {useState} from "react";
import {createProxyStorage} from "stokado";
import {saveAs} from 'file-saver';
import {FileProcessor} from "../Class/FileProcessor";

export function Generator() {

    const [randomAction, setRandomAction] = useState([""])
    const [monthlyAction, setMonthlyAction] = useState([{date: 1, action: "", isGood: true}])
    const [weaklyAction, setWeaklyAction] = useState([{day: 1, action: "", isGood: true}])

    const [goodActionCount, setGoodActionCount] = useState(3)
    const [badActionCount, setBadActionCount] = useState(3)
    return (
        <div>
            <Container maxWidth={"xl"}>
                <Typography variant={"h4"} align={"center"} mt={2}>工程師農民曆產生器</Typography>
                <Card>
                    <CardContent>
                        <Typography variant={"h5"}>農民曆設定</Typography>
                        <Grid container spacing={2} sx={{mt:0}}>
                            <Grid item>
                                一天中 宜 最多數量
                            </Grid>
                            <Grid item xs={true}>
                                <TextField variant={"standard"} value={goodActionCount} fullWidth onChange={(e) => {
                                    setGoodActionCount(e.target.value)
                                }}/>
                            </Grid>
                            <Grid item>
                                個
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{mt:0}}>
                            <Grid item>
                                一天中 忌 最多數量
                            </Grid>
                            <Grid item xs={true}>
                                <TextField variant={"standard"} value={badActionCount} fullWidth onChange={(e) => {
                                    setBadActionCount(e.target.value)
                                }}/>
                            </Grid>
                            <Grid item>
                                個
                            </Grid>
                        </Grid>


                    </CardContent>
                </Card>

                <Card sx={{mt: 6}}>
                    <CardContent>
                        <Typography variant={"h5"}>每月宜忌</Typography>

                        <Grid container spacing={2} mt={2}>
                            {monthlyAction.map((item, index) => {
                                return (
                                    <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                每月
                                                <Select
                                                    sx={{ml: 2, mr: 2, width: 50}}
                                                    value={item.date}
                                                    label="日期"
                                                    variant={"standard"}
                                                    onChange={(e) => {

                                                        let newAction = [...monthlyAction]
                                                        newAction[index] = {
                                                            date: e.target.value,
                                                            action: item.action,
                                                            isGood: item.isGood
                                                        }
                                                        setMonthlyAction(newAction)
                                                    }}
                                                >
                                                    {[...Array(31).keys()].map((item, index) => {
                                                        return (
                                                            <MenuItem key={index} value={item + 1}>{item + 1}</MenuItem>
                                                        )
                                                    })}
                                                </Select> 號
                                            </Grid>
                                            <Grid item>
                                                <Select
                                                    sx={{ml: 2, mr: 2, width: 50}}
                                                    value={item.isGood}
                                                    label="宜忌"
                                                    variant={"standard"}
                                                    onChange={(e) => {
                                                        let newAction = [...monthlyAction]
                                                        newAction[index] = {
                                                            date: item.date,
                                                            action: item.action,
                                                            isGood: e.target.value
                                                        }
                                                        setMonthlyAction(newAction)
                                                    }}
                                                >
                                                    <MenuItem value={true}>宜</MenuItem>
                                                    <MenuItem value={false}>忌</MenuItem>
                                                </Select>
                                            </Grid>
                                            <Grid item xs={true}>
                                                <TextField value={item.action} fullWidth variant={"standard"}
                                                           placeholder={"請輸入宜忌"}
                                                           onChange={(e) => {
                                                               let newAction = [...monthlyAction]
                                                               newAction[index] = {
                                                                   date: item.date,
                                                                   action: e.target.value,
                                                                   isGood: item.isGood
                                                               }
                                                               if (newAction[newAction.length - 1].action !== "" && index === newAction.length - 1) {
                                                                   newAction.push({date: 1, action: "", isGood: true})
                                                               }
                                                               if (newAction[newAction.length - 2].action === "" && newAction.length > 1) {
                                                                   newAction.pop()
                                                               }
                                                               setMonthlyAction(newAction)
                                                           }}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>

                        <Typography variant={"body2"} p={2}>共 {monthlyAction.length - 1} 個</Typography>
                    </CardContent>
                </Card>

                <Card sx={{mt: 6}}>
                    <CardContent>
                        <Typography variant={"h5"}>每週宜忌</Typography>

                        <Grid container spacing={2} mt={2}>
                            {weaklyAction.map((item, index) => {
                                return (
                                    <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                每週
                                                <Select
                                                    sx={{ml: 2, mr: 2, width: 50}}
                                                    value={item.day}
                                                    label="日期"
                                                    variant={"standard"}
                                                    onChange={(e) => {

                                                        let newAction = [...weaklyAction]
                                                        newAction[index] = {
                                                            day: e.target.value,
                                                            action: item.action,
                                                            isGood: item.isGood
                                                        }
                                                        setWeaklyAction(newAction)
                                                    }}
                                                >
                                                    {["日", "一", "二", "三", "四", "五", "六"].map((item, index) => {
                                                        return (
                                                            <MenuItem key={index} value={index}>{item}</MenuItem>
                                                        )
                                                    })}
                                                </Select> 號
                                            </Grid>
                                            <Grid item>
                                                <Select
                                                    sx={{ml: 2, mr: 2, width: 50}}
                                                    value={item.isGood}
                                                    label="宜忌"
                                                    variant={"standard"}
                                                    onChange={(e) => {

                                                        let newAction = [...weaklyAction]
                                                        newAction[index] = {
                                                            day: item.day,
                                                            action: item.action,
                                                            isGood: e.target.value
                                                        }
                                                        setWeaklyAction(newAction)
                                                    }}
                                                >
                                                    <MenuItem value={true}>宜</MenuItem>
                                                    <MenuItem value={false}>忌</MenuItem>
                                                </Select>
                                            </Grid>
                                            <Grid item xs={true}>
                                                <TextField value={item.action} fullWidth variant={"standard"}
                                                           placeholder={"請輸入宜忌"}
                                                           onChange={(e) => {
                                                               let newAction = [...weaklyAction]
                                                               newAction[index] = {
                                                                   day: item.day,
                                                                   action: e.target.value,
                                                                   isGood: item.isGood
                                                               }
                                                               if (newAction[newAction.length - 1].action !== "" && index === newAction.length - 1) {
                                                                   newAction.push({day: 1, action: "", isGood: true})
                                                               }
                                                               if (newAction[newAction.length - 2].action === "" && newAction.length > 1) {
                                                                   newAction.pop()
                                                               }
                                                               setWeaklyAction(newAction)
                                                           }}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>


                        <Typography variant={"body2"} p={2}>共 {weaklyAction.length - 1} 個</Typography>
                    </CardContent>
                </Card>

                <Card sx={{mt: 6}}>
                    <CardContent>
                        <Typography variant={"h5"}>剩餘宜忌填充</Typography>
                        <Grid container spacing={2} mt={2}>
                            {randomAction.map((item, index) => {
                                return (
                                    <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                                        <TextField value={item} fullWidth onChange={(e) => {
                                            let newAction = [...randomAction]
                                            newAction[index] = e.target.value
                                            if (newAction[newAction.length - 1] !== "" && index === newAction.length - 1) {
                                                newAction.push("")
                                            }
                                            if (newAction[newAction.length - 2] === "" && newAction.length > 1) {
                                                newAction.pop()
                                            }
                                            setRandomAction(newAction)
                                        }}/>
                                    </Grid>
                                )

                            })}
                        </Grid>
                        <Typography variant={"body2"} p={2}>共 {randomAction.length - 1} 個</Typography>
                    </CardContent>
                </Card>

                <Box sx={{mt: 2}}>
                    <Button variant={"contained"} sx={{mr: 2}} onClick={() => {
                        const storage = createProxyStorage(localStorage)
                        storage.setItem("monthlyAction", monthlyAction)
                        storage.setItem("weaklyAction", weaklyAction)
                        storage.setItem("randomAction", randomAction)

                    }}>
                        暫時儲存
                    </Button>
                    <Button variant={"contained"} sx={{mr: 2}} onClick={() => {
                        const storage = createProxyStorage(localStorage)
                        setMonthlyAction(storage.getItem("monthlyAction"))
                        setWeaklyAction(storage.getItem("weaklyAction"))
                        setRandomAction(storage.getItem("randomAction"))

                    }}>
                        從暫存檔讀取
                    </Button>
                    <Button variant={"contained"} color={"success"} onClick={() => {
                        const processor = new FileProcessor()

                        processor.setMonthlyAction(monthlyAction)
                        processor.setWeaklyAction(weaklyAction)
                        processor.setRandomAction(randomAction)

                        saveAs(new Blob([processor.toBinary()], {type: "elc/binary"}), "data.dat");
                    }}>
                        匯出
                    </Button>
                </Box>
            </Container>
        </div>
    );
}