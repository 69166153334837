import './App.css';
import {Container, Grid, Typography} from "@mui/material";
import Calendar from "./Components/Calendar";
import Detail from "./Components/Detail";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {useEffect, useState} from "react";
import {Route, Routes, useParams} from "react-router-dom";

function App() {
    const [isROCCalendar, setIsROCCalendar] = useState(true);


    const [selectedDate, setSelectedDate] = useState(new Date());
    const [previewDate, setPreviewDate] = useState(new Date());

    const params = useParams();

    useEffect(() => {
        //mount
        selectedDate.setFullYear(params.year)
        selectedDate.setMonth(params.month - 1)
        selectedDate.setDate(params.date)
        setPreviewDate(new Date(selectedDate))
    }, []);

    return (
        <div>
            <Container maxWidth={"xl"}>
                <Typography variant={"h3"} align={"center"} mt={2}>工程師農民曆</Typography>
                <Grid container sx={{mt: 2}} spacing={2}>
                    <Grid item xs={6}>
                        <Calendar previewDate={previewDate}
                                  selectedDate={selectedDate}
                                  onPrevMonth={() => {
                                      let date = new Date(previewDate)
                                      date.setMonth(date.getMonth() - 1)
                                      setPreviewDate(date)
                                  }}
                                  onNextMonth={() => {
                                      let date = new Date(previewDate)
                                      date.setMonth(date.getMonth() + 1)
                                      setPreviewDate(date)
                                  }}
                                  isROCCalendar={isROCCalendar}
                                  onIsROCCalendarChange={() => {
                                      setIsROCCalendar(!isROCCalendar)
                                  }}
                                  onDateSelected={(date) => {
                                      setSelectedDate(date)
                                  }}/>
                        {/*<Typography>{previewDate.getFullYear() + "/" + (previewDate.getMonth() + 1) + "/" + previewDate.getDate()}</Typography>*/}
                        {/*<Typography>{selectedDate.getFullYear() + "/" + (selectedDate.getMonth() + 1) + "/" + selectedDate.getDate()}</Typography>*/}
                    </Grid>
                    <Grid item xs={6}>
                        <Detail selectedDate={selectedDate}/>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default App;
