import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Navigate, Route, Routes,} from "react-router-dom";
import {Generator} from "./Page/Generator";

const root = ReactDOM.createRoot(document.getElementById('root'));

const date = new Date();
root.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path={"/:year/:month/:date"} element={<App/>}/>
                <Route path={"/*"} element={<Navigate
                    to={"/" + date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()}
                    replace={true}/>}/>
                <Route path={"/generator"} element={<Generator/>}/>
            </Routes>
        </Router>,
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
