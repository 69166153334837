export class FileProcessor {


    constructor() {
        // this.monthlyAction;
        // this.weaklyAction;
        // this.randomAction;

    }

    setMonthlyAction(monthlyAction) {
        this.monthlyAction = monthlyAction;
    }

    setWeaklyAction(weaklyAction) {
        this.weaklyAction = weaklyAction;
    }

    setRandomAction(randomAction) {
        this.randomAction = randomAction;
    }

    toBinary() {


        let buffer = new ArrayBuffer(8) // allocates 8 bytes
        let data = new DataView(buffer)

        // You can write uint8/16/32s and float32/64s to dataviews

        //Test byte
        data.setUint8(0, 0x01);
        data.setUint16(1, 0x2345);
        data.setUint32(3, 0x6789ABCD);
        data.setUint8(7, 0xEF);
        return buffer
    }
}
