import {Box, Card, Divider, Grid, IconButton, Stack, styled, Switch, Typography} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {createUseStyles} from "react-jss";


const DateLink = styled(Link)({
    textDecoration: "none",
    color: "unset",
    display: "block",
})

const useStyles = createUseStyles({
    selected: {
        background: 'dodgerblue',
        color: 'white',
        borderRadius: "50%",

    },
})
export default function Calendar({
                                     previewDate,
                                     selectedDate,
                                     onNextMonth,
                                     onPrevMonth,
                                     isROCCalendar,
                                     onIsROCCalendarChange,
                                     onDateSelected
                                 }) {


    let [date, setDate] = useState(new Date(previewDate));

    useEffect(() => {
    }, []);

    useEffect(() => {
        date.setDate(1)//Need to set the date to 1 to get the beginning of the month
        date.setMonth(previewDate.getMonth())//The month is already 0-based
        date.setFullYear(previewDate.getFullYear())

        setDate(new Date(date))
    }, [previewDate]);


    return (
        <>
            <Card>
                <Grid container>
                    <Grid item xs={1}>
                        <IconButton onClick={() => {
                            onPrevMonth()
                        }}>
                            <KeyboardArrowLeftIcon/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={true}>
                        <Typography variant={"h4"}
                                    align={"center"}>{(isROCCalendar && previewDate.getFullYear() - 1911 >= 1) ? "民國" + (date.getFullYear() - 1911) : date.getFullYear()} 年 {date.getMonth() + 1} 月</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={() => {
                            onNextMonth()
                        }}>
                            <KeyboardArrowRightIcon/>
                        </IconButton>

                    </Grid>
                </Grid>
                <Divider/>
                <Grid container sx={{height: 40, fontSize: 20, alignContent: "center"}}>
                    {["日", "一", "二", "三", "四", "五", "六"].map((v, k) => {
                        return <Grid item xs={true} textAlign={"center"} key={k}>{v}</Grid>
                    })}
                </Grid>
                {
                    Array.from({length: 6}, (_, i) => {
                        let maxDate = getDaysInMonth(date.getMonth() + 1, date.getFullYear())

                        let isFirstRow = i === 0

                        let start = 1 + (i * 7) - (isFirstRow ? 0 : date.getDay())

                        let isLastRow = start + 7 > maxDate

                        if (start > maxDate) {
                            return null
                        }
                        let lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)

                        return <CalendarRow key={i} start={start}
                                            beginDay={isFirstRow ? date.getDay() : 0}
                                            endDay={isLastRow ? lastDate.getDay() : 6} previewDate={previewDate}
                                            selectedDate={selectedDate} onDateSelected={onDateSelected}/>
                    })
                }
                <Divider/>


                <Stack direction="row" spacing={1} alignItems="center" ml={2}>
                    <Typography>西曆</Typography>
                    <Switch checked={isROCCalendar && previewDate.getFullYear() - 1911 >= 1}
                            disabled={previewDate.getFullYear() - 1911 < 1} onChange={() => {
                        onIsROCCalendarChange()
                    }}/>
                    <Typography>民國曆</Typography>
                </Stack>
            </Card>
        </>
    );
}

function CalendarRow({start, beginDay, endDay, selectedDate, previewDate, onDateSelected}) {
    const classes = useStyles()


    return (
        <>
            <Grid container sx={{height: 60, fontSize: 20, alignContent: "center"}}>
                {
                    Array.from({length: 7}, (item, i) => {

                        if (beginDay > i || endDay < i) {
                            return <Grid item xs={true} textAlign={"center"} key={i}></Grid>
                        } else {
                            let isSelectedDate = selectedDate.getDate() === (start + i - beginDay) &&
                                previewDate.getMonth() === selectedDate.getMonth() &&
                                previewDate.getFullYear() === selectedDate.getFullYear()

                            return <Grid item xs={true} textAlign={"center"} key={i} sx={{height: 60}}>
                                <Box component={DateLink}
                                     to={"/" + previewDate.getFullYear() + "/" + (previewDate.getMonth() + 1) + "/" + (start + i - beginDay)}
                                     onClick={(e) => {
                                         onDateSelected(new Date(previewDate.getFullYear(), previewDate.getMonth(), start + i - beginDay))
                                     }}>
                                    <Box sx={{height: 60, width: 60, lineHeight: "60px", margin: "auto"}}
                                         className={(isSelectedDate) ? classes.selected : null}>{start + i - beginDay}</Box>
                                </Box>
                            </Grid>
                        }
                    })
                }
            </Grid>
        </>
    )
}

function getDaysInMonth(m, y) {
    // eslint-disable-next-line no-mixed-operators
    return m === 2 ? y & 3 || !(y % 25) && y & 15 ? 28 : 29 : 30 + (m + (m >> 3) & 1);
}
