import {Box, Chip, Typography} from "@mui/material";

export default function Detail({selectedDate}) {
    return (
        <Box>
            <Typography variant={"h4"}>
                {selectedDate.getFullYear() + "/" + (selectedDate.getMonth() + 1) + "/" + selectedDate.getDate()} 的宜忌如下
            </Typography>

            <Box sx={{mt: 1}}/>

            <Box color={"green"} fontSize={"large"}>
                宜：
                <Chip label={"遷移資料庫"} color={"success"}/>

            </Box>

            <Box sx={{mt: 4}}/>

            <Box color={"red"} fontSize={"large"}>
                忌：
                <Chip label={"寫 Java"} color={"error"}/>
            </Box>
        </Box>
    )
}